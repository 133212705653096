@use '../base/index' as *;

/* ======================================================================================================================== */
/* General mantine components non-theme related overrides */

/* Default Overrides */

.mantine-Modal-root {
	.mantine-Modal-overlay {
		backdrop-filter: blur(14px);
		background-color: rgb(0 0 0 / 0.1);
	}

	.mantine-Modal-content {
		position: relative;
		display: grid;
		align-content: start;
		grid-template-rows: 1fr;

		&:has(.mantine-Modal-header) {
			grid-template-rows: auto 1fr;
		}
	}

	.mantine-Modal-title {
		font-size: large;

		// font-size: x-large;
		font-weight: 600;
		text-transform: uppercase;
	}

	.mantine-Modal-body {
		// display: grid;

		// height: 100%;
		// align-content: center;
		border-radius: 6px;
	}
}

.mantine-InputWrapper-error {
	padding-inline: 0.25rem;
}

.mantine-Button-root[data-variant='gradient'] {
	--mantine-primary-color-filled: linear-gradient(
		140deg,
		var(--mantine-primary-color-5) 0%,
		var(--mantine-primary-color-7) 30%,
		var(--mantine-primary-color-7) 70%,
		var(--mantine-primary-color-6) 100%
	);
}

.mantine-TextInput-error {
	font-weight: 600;
}

/* ======================================================================================================================== */
/* light-dark overrides */

.mantine-Paper-root {
	--mantine-color-body: light-dark(hsl(0deg 0% 99%), var(--mantine-color-dark-5));
}

.mantine-Notifications-root {
	.mantine-Notification-title {
		font-weight: 700;
	}

	.mantine-Notification-description {
		color: light-dark(var(--mantine-color-text), var(--mantine-color-bright));
	}
}

.mantine-InputWrapper-required {
	display: inline-block;
	font-size: 24px;
	line-height: 0.75rem;
	vertical-align: bottom;
}

/* ======================================================================================================================== */
/* dark only overrides */

body {
	--mantine-color-body: light-dark(hsl(0deg 0% 96%), var(--mantine-color-dark-9));
	--mantine-color-error: var(--mantine-color-danger-4);
}

@include dark {
	--mantine-color-text: white;
	--mantine-color-placeholder: color-mix(in srgb, var(--mantine-color-bright) 50%, transparent);
	--mantine-color-dimmed: color-mix(in srgb, var(--mantine-color-text) 70%, transparent);
	--background-default-gradient: linear-gradient(180deg, rgb(25 29 48) 0%, rgb(14 16 36) 100%);
	/* variable overrides */

	/* mantine components overrides */
	.mantine-Pill-root {
		background-color: var(--mantine-color-dark-1);

		.mantine-Pill-label {
			color: white;
		}
	}

	.mantine-Button-root[data-disabled='true'] {
		background-color: var(--mantine-color-dark-5);
		color: var(--mantine-color-gray-8); // color-mix(in srgb, var(--mantine-color-bright) 20%, transparent);
	}

	/* give all modals, that are not fullscreen a gradient background for the paper */
	.mantine-Modal-root:not([data-full-screen='true']) {
		// .mantine-Modal-content {
		// 	// background: var(--background-default-gradient);
		// }

		.mantine-Modal-header {
			background-color: transparent;
		}
	}

	.mantine-focus-auto:focus-visible {
		outline: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-primary-color-9);
	}

	.mantine-Switch-root:has(input:not(:checked)) .mantine-Switch-track {
		background-color: var(--mantine-primary-color-light-hover);
	}

	.mantine-Checkbox-input {
		outline: 1px solid var(--mantine-primary-color-light-hover);
	}

	.mantine-Input-input {
		--input-bd: var(--mantine-color-gray-8);
	}

	.mantine-TextInput-input {
		--input-bd: var(--mantine-color-dark-1);
		--input-color: var(--mantine-color-bright);
		--input-disabled-color: var(--mantine-color-bright);

		&:disabled {
			--input-disabled-bg: var(--mantine-color-dark-1);
		}

		&:focus-within {
			--input-bd: var(--mantine-primary-color-9);
		}

		&::placeholder {
			opacity: 0.6;
		}
	}

	* {
		--divider-color: color-mix(in srgb, var(--mantine-color-text) 20%, transparent);
	}
}

/* --------------------------------------------------------------------------- */
/* MONOCHROME BUTTON set mod={{monochrome: true}} */
/* --------------------------------------------------------------------------- */

.mantine-Button-root[data-monochrome='true'] {
	--mantine-color-primary-light-color: var(--mantine-color-black);
	--mantine-color-primary-filled: var(--mantine-color-black);
	--mantine-primary-color-filled: var(--mantine-color-black);
	--mantine-primary-color-filled-hover: var(--mantine-color-dark-0);
	--mantine-color-primary-filled-hover: var(--mantine-color-dark-0);
	--mantine-color-primary-outline: var(--mantine-color-black);
	--mantine-color-primary-outline-hover: var(--mantine-color-gray-0);
	--mantine-color-primary-light: var(--mantine-color-gray-2);
	--mantine-color-primary-light-hover: var(--mantine-color-gray-2);

	&[data-variant='gradient'] {
		--button-bg: linear-gradient(45deg, var(--mantine-color-dark-4) 0%, var(--mantine-color-dark-9) 100%) !important;
		--mantine-color-black: var(--mantine-color-white);

		&:hover {
			--button-bg: linear-gradient(45deg, var(--mantine-color-dark-0) 0%, var(--mantine-color-dark-3) 100%) !important;
		}
	}
}

@include dark {
	.mantine-Button-root[data-monochrome='true'] {
		--mantine-color-primary-light-color: white;
		--mantine-color-primary-filled: white;
		--mantine-primary-color-filled: white;
		--mantine-primary-color-filled-hover: var(--mantine-color-gray-3);
		--mantine-color-primary-filled-hover: var(--mantine-color-gray-3);
		--mantine-color-primary-outline: white;
		--mantine-color-primary-outline-hover: var(--mantine-color-gray-9);
		--mantine-color-primary-light: var(--mantine-color-gray-9);
		--mantine-color-primary-light-hover: var(--mantine-color-gray-7);

		&[data-variant='filled'],
		&:not([data-variant]) {
			--mantine-color-white: black;
		}

		&[data-variant='gradient'] {
			--button-bg: linear-gradient(45deg, white 0%, var(--mantine-color-gray-2) 100%) !important;
			--mantine-color-white: black;

			&:hover {
				--button-bg: linear-gradient(
					45deg,
					var(--mantine-color-gray-2) 0%,
					var(--mantine-color-gray-5) 100%
				) !important;
			}
		}

		&[data-variant='white'] {
			--mantine-color-primary-filled: black;
		}
	}
}
