/* Overrides for Electron */

body:has(.frame.standalone) {
	--frame-bar-height: 40px;

	.mantine-Modal-inner {
		// display: none;
		inset: var(--frame-bar-height) 0 0;
	}

	.mantine-Modal-overlay {
		inset: var(--frame-bar-height) 0 0;
	}

	.cl-modalBackdrop {
		top: var(--frame-bar-height);
	}
}

body.fullscreen {
	--frame-bar-height: 0px !important;
}
