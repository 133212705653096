@use '../base' as *;

/* --------------------------------------------------------------------------- */
/* TYPOGRAPHY */

@font-face {
	font-display: swap;
	font-family: 'Mona Sans';
	font-style: normal;
	src: url('/static/fonts/Mona-Sans.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Hubot Sans';
	font-style: normal;
	src: url('/static/fonts/Hubot-Sans.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: FrachterMono;

	// font-feature-settings: 'calt', 'dlig', 'ss01', 'ss02', 'ss03', 'ss04', 'ss05', 'ss06', 'ss07', 'ss08';
	// font-style: normal;
	// src: url('/static/fonts/MonaspaceNeonVarVF[wght,wdth,slnt].woff2');
	// HINT: Monaspace Neon has a bad baseline so we cannot use it
	src: url('/static/fonts/RedditMono-VariableFont_wght.ttf');
}

/* --------------------------------------------------------------------------- */
/* BASE */

body {
	--scale: #{$scale};
	--root-font-size: #{$root-font-size};
	--color-brand: #{$color-brand};
	--color-brand-secondary: #{$color-brand-secondary};
	--z-index-network-connection-guard: 9000;

	/* fixed sizing vars */
	--frame-bar-height: 0px;
	--org-appbar-height: rem(70);
	--sidebar-settings-width: rem(280);
	--sidebar-apps-width: rem(60);
	--divider-color: light-dark(
		var(--mantine-color-gray-3),
		color-mix(in srgb, var(--mantine-color-text) 10%, transparent)
	);
	--page-max-width: rem(800);
	min-height: 100vh;
	min-height: 100dvh;
}

/* fix to display links that are not wrapped in mantine components in mantine color */
a:not([class^='mantine-']) {
	color: var(--mantine-primary-color-filled);
}

fieldset {
	padding: 0;
	border: none;
	margin: 0;
}

* {
	hyphens: auto;
}
