/* HINT: 
  use separate layers.scss file to import in nextjs layout
  Otherwise layer order will not be as expected because of how scss / next import and mange styles
 */

/* mantine styles are already presented as `mantine` layer */
// @import './mantine';
@use 'sass:meta';
@use 'clerk';
@use 'electron';

/* HINT: use .layer versions of mantine. which add their styles under the `@layer mantine` ! */
@import '~/node_modules/@mantine/core/styles.layer.css';
@import '~/node_modules/@mantine/dates/styles.layer.css';

@layer theme {
	@include meta.load-css('theme');
}

@layer mantine_custom {
	@include meta.load-css('mantine_custom');
}
